
.find-journeys {
    .logo {
        width: 191px;
        height: 47px;
    }
    
    .text-suggested {
        color: #383838;
        font-size: 12px;
        line-height: 14px;
    }    

    .text-regular {
        color: #000000;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 14px;
        text-align: center;
    }    

    ion-item {
        border: 1px solid #EBEBEB;
        border-radius: 39.5px;
    }

    ion-button {
        --color: black;
        font-size: 12px;
        line-height: 14px;
    }
    
    ion-label {
        font-size: 16px !important;
        line-height: 18px;
        white-space: normal !important;
    }

    .next-button {
        font-size: 16px;
        line-height: 18px;
        --color: white;
        --background: #31BBE9;
        --border-radius: 0.25em;
    }
}

