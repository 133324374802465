body {
  /*
    Set background to white because otherwise it's black which is noticable during page transitions.
    */
  background: white;
}

/*
 * Style the date picker.
 */
.DayPicker-Footer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

ion-button {
  text-transform: none;
}

ion-item {
  --background-activated-opacity: 0.025;
}

ion-item {
  --padding-start: 0px;
  --padding-end: 0px;
  --padding-top: 0px;
  --padding-bottom: 0px;
  --inner-padding-start: 0px;
  --inner-padding-end: 0px;
  --inner-padding-top: 0px;
  --inner-padding-bottom: 0px;
}

ion-img::part(image) {
  border-radius: 3px;
}

a {
  color: black;
  text-decoration: none;
  font-weight: bold;
}

.card {
  user-select: none;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 4px 16px;
}

* {
  /*
    * Enable better touch scrolling on iOS.
    */
  -webkit-overflow-scrolling: touch !important;
}

/*
 * Logos
 *
 */

.heading-logo {
  width: 191px;
  height: 47px;
}

.onroute-logo-background {
  background: url("./assets/onboarding-logo.svg");
  background-position: left;
  background-repeat: no-repeat;
}

.balloon-logo {
  padding: 20px;
  background: rgba(95, 191, 230, 0.15);
  width: 100px;
  height: 100px;
  border-radius: 55px;
}

/*
 * Text
 *
 */

.text-title {
  color: #000000;
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
}

.text-header {
  color: #000000;
  font-size: 25px;
  font-weight: 500;
  line-height: 29px;
}

.text-message {
  color: #6d6d6d;
  font-size: 16px;
}

.text-regular {
  font-size: 16px;
  line-height: 19px;
  color: #4c4c4c;
}

.text-error {
  font-size: 16px;
}

.text-name {
  color: #000000;
  font-size: 12px;
  font-weight: bold;
}

.text-handle {
  color: #6c6c6c;
  font-size: 12px;
}

.text-comment {
  color: #000000;
  font-size: 12px;
}

.text-description {
  color: #000000;
  font-size: 14px;
  line-height: 22px;
}

.text-read-more {
  color: #07b7e0;
  font-size: 14px;
  line-height: 22px;
}

.text-profile {
  color: #000000;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}

.text-time {
  opacity: 0.6;
  color: #282828;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
}

.text-location {
  color: rgba(0, 0, 0, 0.52);
  font-size: 11px;
  line-height: 13px;
}

.text-count {
  color: #000000;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
}

.text-count-label {
  opacity: 0.6;
  color: #282828;
  font-size: 11px;
  font-weight: 500;
  line-height: 13px;
}

.text-link {
  color: #07b7e0;
  font-size: 14px;
  line-height: 16px;
  font-weight: normal;
  line-height: 14px;
}

.page-border {
  border-bottom: 1px solid #e7e9ee;
}

.vertical-divider {
  border-right: 1px solid #e7e9ee;
}

/*
 * Errors
 */

ion-item.text-error {
  --border-radius: 3px;
}

/*
 * Buttons
 *
 */

.main-button {
  font-size: 16px;
  line-height: 18px;
  --color: white;
  --background: #5fbfe6;
  --border-radius: 0.25em;
  font-weight: bold;
  opacity: 1;
}

.alt-button {
  --color: #8da6b1;
  --border-color: #8da6b1;
  --border-width: 1px;
  --border-style: solid;
  --background: white;
  --border-radius: 0.25em;
  font-weight: bold;
}

.facebook-button {
  --background: #435498;
  --border-radius: 0.5em;
}

.google-button {
  --color: black;
  --background: white;
  --border-radius: 0.5em;
}

.twitter-button {
  --background: #3ea7ec;
  --border-radius: 0.5em;
}

.post-additional-button {
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 22px;
  color: #3b3b3b;
  --border-color: #ebebeb;
  --border-width: 1px;
  --border-radius: 1em;

  ion-icon {
    color: #31bbe9;
  }
}
