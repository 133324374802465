
.offline-screen {

    ion-button {
        font-size: 16px;
        line-height: 18px;
    }
   
}

