.onboarding {
  ion-button {
    font-size: 16px;
    line-height: 18px;
  }

  .button-loading {
    bottom: 0;
  }

  .onboarding-heading {
    h1 {
      color: #5fbfe6;
      font-size: 44px;
      font-weight: 600;
    }
  }

  .onboarding-screen {
    background: url("../assets/onboarding-logo.svg");
    background-position: left;
    background-repeat: no-repeat;
  }

  .sign-up-link {
    text-align: center;
  }
}
