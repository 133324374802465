
.feed-screen {
    .logo {
        width: 191px;
        height: 47px;
    }

    .logo-sm {
        width: 95.5px;
        height: 23.5px;
    }
   
}

