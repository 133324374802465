.bottom-drawer {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  margin-left: 0;
  margin-right: 0;
  z-index: 100000 !important;
  background-color: white;
  transform: translateY(50vh);
  overflow: scroll;
}

.drawer-button {
  font-size: 24px;
  height: 5px;
  margin: 0 auto;
}
