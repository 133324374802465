.view-posts {
  .img-row {
    height: 300px;
  }
}

.post-buttons {
  --padding-end: 0;
  --padding-start: 0;
}

.feedSlide {
  width: 100%;
  height: auto;
}
