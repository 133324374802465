.onboarding-heading {
  h1 {
    color: #5fbfe6;
    font-size: 44px;
    font-weight: 600;
  }
}

.heading-logo {
  width: 191px;
  height: 47px;
}

.login-screen {
  background: url("../assets/onboarding-logo.svg");
  background-position: left;
  background-repeat: no-repeat;
}

.forgot-password-link {
  color: #5fbfe6;
  font-weight: normal;
}
