
.profile-screen {
    
    ion-avatar.profile-avatar {
        --border-radius: 0.25em;
    }

    .edit-profile-button {
        font-size: 12px;
        line-height: 14px;
        font-weight: bold;
        --color: black;
        --background: white;
        --border-style: solid;
        --border-color: black;
        --border-width: 0.15em;
        --border-radius: 0.25em;
    }

    .follow-button {
        font-size: 12px;
        line-height: 14px;
        font-weight: bold;
        --color: black;
        --background: white;
        --border-style: solid;
        --border-color: black;
        --border-width: 0.15em;
        --border-radius: 0.25em;
    }

    .following-button {
        font-size: 12px;
        line-height: 14px;
        font-weight: bold;
        --color: white;
        --background: #31BBE9;
        --border-radius: 0.25em;
    }

    ion-thumbnail {
        --size: 150px;
        --border-radius: 0;
    }
}



