
.post-screen {

    .img-row {
        height: 250px;
    }

    .text-comment {
        /*
        * Fix for IonTextarea height of 0.
        */
        textarea, .textarea-wrapper {
            min-height: 40px !important;
        }
    }
}



