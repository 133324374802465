
.explore-screen {
    ion-thumbnail {
        --size: auto;
        --border-radius: 0;
    }

    ion-img::part(image) {
        border-radius: 0;
    }
}