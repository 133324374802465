
.setup {
    .logo {
        width: 191px;
        height: 47px;
    }
    
    a {
        font-size: 16px;
        line-height: 18px;
        color: black;
    }

    ion-button {
        font-size: 16px;
        line-height: 18px;
    }
    
    ion-label {
        font-size: 16px !important;
        line-height: 18px;
        white-space: normal !important;
    }

    .next-button {
        font-size: 16px;
        line-height: 18px;
        --color: white;
        --background: #31BBE9;
        --border-radius: 0.25em;
    }
}

