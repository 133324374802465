@import "../../node_modules/leaflet/dist/leaflet.css";

.map {
    ion-spinner {
        --color: #07B7E0;

        transform: scale(1.2);
    }
}


